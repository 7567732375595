
//
// alerts.scss
//

.alert-border{
    background-color: $card-bg;
    border-color: $light;
    border-top: 2px solid $light;
  }
  
  @each $color,
  $value in $theme-colors {
      .alert-border-#{$color} {
          border-top-color: $value;
      }
      .alert-outline-#{$color} {
        border: 1px solid lighten($value, 35%);
        color: $value;
    }
  }