
// 
// _popover.scss
//

.popover {
  box-shadow: $popover-box-shadow;
}

.popover-body {
    color: #495057 !important;
}
